import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Content, { HTMLContent } from "../components/Content";

export class ProjectPostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  componentDidMount() {
    if (window) this.setState({ url: window.location.href });
  }

  render() {
    const { url } = this.state;
    const {
      content,
      contentComponent,
      title,
      client,
      bannerImage,
      images
    } = this.props;
    const PostContent = contentComponent || Content;
    const tags =
      this.props.tags.split(",").map(item => item.trim().toLowerCase()) || [];
    const partners =
      this.props.partners.split(",").map(item => item.trim()) || [];

    return (
      <Fragment>
        <div className="container space-1 space-3--md">
          <div className="row">
            <div className="col-md-7 mb-7 mb-md-0">
              <Img
                className="mb-3 rounded"
                fluid={bannerImage.childImageSharp.fluid}
                alt={title}
                title={title}
              />
              {images &&
                images.map(img => {
                  return (
                    <Img
                      className="mb-3 rounded"
                      key={img.childImageSharp.fluid.src}
                      fluid={img.childImageSharp.fluid}
                      alt={title}
                      title={title}
                    />
                  );
                })}
            </div>
            <div className="col-md-5">
              <div className="sidebar-sticky">
                <div className="sticky-block pl-lg-4">
                  <div className="mb-6">
                    <h1>{title}</h1>
                    <PostContent content={content} />
                  </div>

                  <hr className="my-5" />

                  <ul className="list-unstyled mb-0">
                    <li className="media mb-1">
                      <div className="min-width-35">
                        <h2 className="h6">Client</h2>
                      </div>
                      <div className="media-body">
                        <small className="text-muted">{client}</small>
                      </div>
                    </li>

                    <li className="media mb-1">
                      <div className="min-width-35">
                        <h3 className="h6">Partners</h3>
                      </div>
                      <div className="media-body">
                        {partners &&
                          partners.map(t => {
                            return (
                              <small
                                key={t}
                                className="d-block text-muted mb-1"
                              >
                                {t}
                              </small>
                            );
                          })}
                      </div>
                    </li>

                    <li className="media">
                      <div className="min-width-35">
                        <h4 className="h6">Products</h4>
                      </div>
                      <div className="media-body">
                        {tags &&
                          tags.map(t => {
                            return (
                              <small
                                key={t}
                                className="d-block text-muted mb-1"
                              >
                                {t}
                              </small>
                            );
                          })}
                      </div>
                    </li>
                  </ul>

                  <hr className="my-5" />

                  <div className="media">
                    <div className="min-width-35">
                      <h4 className="h6">Share</h4>
                    </div>
                    <div className="media-body">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a
                            className="u-icon u-icon--secondary u-icon--sm"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="fab fa-facebook-f u-icon__inner"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="u-icon u-icon--secondary u-icon--sm twitter-share-button"
                            href={`https://twitter.com/intent/tweet?text=Checkout%20Static%20Power%20PH%20project&url=${url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="fab fa-twitter u-icon__inner"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="u-icon u-icon--secondary u-icon--sm"
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="fab fa-linkedin u-icon__inner"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <Link to="/projects" className="mt-3">
                    Back to Projects
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5" />
      </Fragment>
    );
  }
}

ProjectPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const ProjectPost = ({ data }) => {
  const {
    kdccmsProjects: post,
    site: {
      siteMetadata: { siteUrl }
    }
  } = data;
  const tags = post.tags.split(",").map(item => item.trim().toLowerCase());
  const images = [];
  for (let ctr = 1; ctr <= 10; ctr += 1) {
    if (!post[`image${ctr}`]) continue;
    images.push(post[`image${ctr}`]);
  }

  return (
    <Layout>
      <SEO
        title={`${post.title} | Project`}
        keywords={tags}
        description={`${post.description}`}
        meta={[
          {
            property: `og:image`,
            content: `${siteUrl}${post.bannerImage.childImageSharp.fluid.src}`
          }
        ]}
      />
      <ProjectPostTemplate
        content={post.body}
        contentComponent={HTMLContent}
        description={post.description}
        tags={post.tags}
        title={post.title}
        client={post.client}
        partners={post.partners}
        date={post.publishDate}
        bannerImage={post.bannerImage}
        images={images}
      />
    </Layout>
  );
};

ProjectPost.propTypes = {
  data: PropTypes.shape({
    kdccmsProjects: PropTypes.object,
    site: PropTypes.object
  })
};

export default ProjectPost;

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    kdccmsProjects(id: { eq: $id }) {
      id
      publishDate(formatString: "MMMM DD, YYYY")
      title
      client
      partners
      description
      tags
      body
      bannerImage {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image1 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image4 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image5 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image6 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image7 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image8 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image9 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image10 {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 87, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
